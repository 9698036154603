import React, { Component, useEffect, useState } from 'react';

import ReactApexChart from 'react-apexcharts';

const Barchart = (props: any) => {
    const [series, setSeries] = useState([{
        data: props.values.map((a: any) => a.value)
    }]);

    const [options, setOptions] = useState({
        chart: {
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#556ee6", "#f46a6a", "#34c38f"],
        grid: {
            borderColor: '#f1f1f1',
        },
        xaxis: {
            categories: props.values.map((a: any) => a.key),
        }
    });

    useEffect(() => {
        setSeries([{
            data: props.values.map((a: any) => a.value)
        }]);

        setOptions({
            ...options, xaxis: {
                categories: props.values.map((a: any) => a.key),
            }
        });
    }, [props.values])

    return (
        <React.Fragment>
            <ReactApexChart series={series} type="bar" height="350" />
        </React.Fragment>
    );
}

export default Barchart;