import { gql } from '@apollo/client';
import { client } from '../hooks/GraphQLProvider';
import { Dto } from '../models/dto.model';
import { UploadDto } from '../models/upload-result.dto';
import { api } from './api';
import mime from 'mime-types';

class StorageService {
    public static async privateUpload(file: File): Promise<Dto<UploadDto>> {
        try {
            const { data } = await client.mutate<{ adminPrivateUpload: UploadDto }>({
                mutation: gql`
                    mutation($file: Upload!) {
                        adminPrivateUpload(file: $file) {
                            id
                            url
                            key
                            originName
                            fileMimetype
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
                variables: {
                    file,
                },
            });

            return {
                data: data?.adminPrivateUpload,
            };
        } catch (error) {
            return {
                error: error?.message || 'Ops, ocorreu um erro',
            };
        }
    }

    public static async publicUpload(file: File): Promise<Dto<UploadDto>> {
        try {
            const { data } = await client.mutate<{ adminPublicUpload: UploadDto }>({
                mutation: gql`
                    mutation($file: Upload!) {
                        adminPublicUpload(file: $file) {
                            id
                            url
                            key
                            originName
                            fileMimetype
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
                variables: {
                    file,
                },
            });

            return {
                data: data?.adminPublicUpload,
            };
        } catch (error) {
            return {
                error: error?.message || 'Ops, ocorreu um erro',
            };
        }
    }

    public static async download(url: string): Promise<Dto<string>> {
        try {
            const result = await api.get(url, { responseType: 'blob' });
            const base64 = await StorageService.convertToBase64(result.data);

            return {
                data: base64,
            };
        } catch (error) {
            return {
                error: error?.message || 'Ops, ocorreu um erro',
            };
        }
    }

    public static async forceDownload(url: string) {

        const result = await api.get(url, { responseType: 'blob' });

        // Create an invisible A element
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);

        // Set the HREF to a Blob representation of the data to be downloaded
        a.href = window.URL.createObjectURL(
            new Blob([result.data], { type: result.headers['content-type'] || 'image/png' }),
        );

        let ext = mime.extension(result.headers['content-type'] || 'image/png');

        if (!ext) {
            ext = "png";
        }

        // Use download attribute to set set desired file name
        a.setAttribute("download", `download.${ext}`);

        // Trigger the download by simulating click
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    }

    public static async downloadFile(data: any, fileName: string, type = "text/plain") {
        // Create an invisible A element
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);

        // Set the HREF to a Blob representation of the data to be downloaded
        a.href = window.URL.createObjectURL(
            new Blob([data], { type })
        );

        // Use download attribute to set set desired file name
        a.setAttribute("download", fileName);

        // Trigger the download by simulating click
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    }

    public static async convertToBase64(file: Blob) {
        return new Promise<string>((resolve) => {
            const r = new FileReader();
            r.onloadend = () => {
                resolve(r.result as string);
            };
            r.readAsDataURL(file);
        });
    }
}

export { StorageService };
