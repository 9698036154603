import { Select as AntSelect, Input } from "antd";

import styled from "@emotion/styled";

const { Search: BaseSearch } = Input;

const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "10px 15px",
  background: `transparent`,
  fontSize: "15px"
});

const PopupContainer = styled("div")({
  minWidth: "250px"
});

const FilterItem = styled("div")({
  "&:not(:last-of-type)": {
    marginRight: "20px"
  },
  cursor: "pointer",
  "&.ant-input-search .ant-input-affix-wrapper": {
    border: "none",
    outline: "none"
  }
});

const PopupFilterItem = styled("div")({
  "&:not(:last-of-type)": {
  },
  cursor: "pointer",
  "&.ant-input-search .ant-input-affix-wrapper": {
    border: "none",
    outline: "none"
  }
});

const Text = styled("span")({
  lineHeight: 2,
  marginLeft: "5px"
});

const Search = styled(BaseSearch)({
  ".ant-input": {
    fontSize: "15px",
    width: "100%"
  }
});

const Select = styled(AntSelect)({
  minWidth: "100%"
});

const SortOrder = styled("span")({
  "&, path": {
    transition: '1000s'
  },
  "&:hover": {
    "&, path": {
      color: "transparent",
      stroke: "gray"
    }
  }
});

export { Container, PopupContainer, FilterItem, PopupFilterItem, Text, Search, Select, SortOrder };