class BasePage {
    public static prepare(setFieldsValue: (data: any) => any) {

        setTimeout(() => {
            const zipCode = (document.getElementById("zipCode") || document.getElementById("postalcode")) as any;

            if (zipCode) {
                zipCode.onchange = () => {

                    fetch(`https://viacep.com.br/ws/${zipCode.value.replace('-', '')}/json/`).then(async (result) => {
                        const address = await result.json();

                        setFieldsValue({ address1: address.logradouro });
                        setFieldsValue({ address: address.logradouro });
                        setFieldsValue({ district: address.bairro });
                        setFieldsValue({ neighborhood: address.bairro });
                        setFieldsValue({ city: address.localidade });
                        setFieldsValue({ state: address.uf });
                    });
                };
            }
        }, 3000);
    }
}

export { BasePage };