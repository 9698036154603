import {
	Alert,
	Button,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Link, withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { apiError, checkLogin } from '../../store/actions';

import { connect } from 'react-redux';
import logodark from '../../assets/images/logo-dark.png';
import { useAuth } from '../../hooks/Auth';

// Redux

// availity-reactstrap-validation

// actions

// import images

const Login: React.FC<any> = (props: any) => {
	const { signIn } = useAuth();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const handleSubmit = async (event: any, values: any) => {
		props.checkLogin(values, props.history, signIn);
		// try {
		//     await signIn(username, password);
		// } catch (error) {
		//     alert(error.stack || error.message);
		// }
	};

	useEffect(() => {
		document.body.classList.add('auth-body-bg');
		return () => {
			document.body.classList.remove('auth-body-bg');
		};
	});

	return (
		<React.Fragment>
			<div className='home-btn d-none d-sm-block'>
				<Link to='/'>
					<i className='mdi mdi-home-variant h2 text-white'></i>
				</Link>
			</div>

			<div>
				<Container fluid className='p-0'>
					<Row className='no-gutters'>
						<Col lg={4}>
							<div className='authentication-page-content p-4 d-flex align-items-center min-vh-100'>
								<div className='w-100'>
									<Row className='justify-content-center'>
										<Col lg={9}>
											<div>
												<div className='text-center'>
													<div>
														<Link to='/' className='logo'>
															<img src={logodark} height='45' alt='logo' />
														</Link>
													</div>

													<h4 className='font-size-18 mt-4'>Welcome Back!</h4>
													<p className='text-muted'>
														Sign in to continue to fastsimtravel.
													</p>
												</div>

												{props.loginError && props.loginError ? (
													<Alert color='danger'>
														{props?.loginError?.toString()}
													</Alert>
												) : null}

												<div className='p-2 mt-5'>
													<AvForm
														className='form-horizontal'
														onValidSubmit={handleSubmit}
													>
														<FormGroup className='auth-form-group-custom mb-4'>
															<i className='ri-user-2-line auti-custom-input-icon text-dark'></i>
															<Label htmlFor='username'>Username</Label>
															<AvField
																name='username'
																value={username}
																onChange={(e: any) => {
																	setUsername(e.target.value);
																}}
																type='text'
																className='form-control'
																id='username'
																validate={{ email: true, required: true }}
																placeholder='Enter username'
															/>
														</FormGroup>

														<FormGroup className='auth-form-group-custom mb-4'>
															<i className='ri-lock-2-line auti-custom-input-icon text-dark'></i>
															<Label htmlFor='userpassword'>Password</Label>
															<AvField
																name='password'
																value={password}
																onChange={(e: any) => {
																	setPassword(e.target.value);
																}}
																type='password'
																className='form-control'
																id='userpassword'
																placeholder='Enter password'
															/>
														</FormGroup>

														<div className='custom-control custom-checkbox'>
															<Input
																type='checkbox'
																className='custom-control-input'
																id='customControlInline'
															/>
															<Label
																className='custom-control-label'
																htmlFor='customControlInline'
															>
																Remember me
															</Label>
														</div>

														<div className='mt-4 text-center'>
															<Button
																color='warning'
																className='w-md waves-effect waves-light text-dark font-w'
																type='submit'
															>
																Log In
															</Button>
														</div>

														<div className='mt-4 text-center'>
															<Link
																to='/forgot-password'
																className='text-muted'
															>
																<i className='mdi mdi-lock mr-1'></i> Forgot
																your password?
															</Link>
														</div>
													</AvForm>
												</div>

												<div className='mt-5 text-center'>
													{/* <p>Don't have an account ? <Link to="/register" className="font-weight-medium text-primary"> Register </Link> </p> */}
													<p>© {new Date().getUTCFullYear()} fastsimtravel.</p>
												</div>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</Col>
						<Col lg={8}>
							<div className='authentication-bg'>
								<div className='bg-overlay-off'>
									<video autoPlay muted loop className='video-login'>
										<source
											src={require('../../assets/videos/video.mp4')}
											type='video/mp4'
										/>
									</video>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

const mapStatetoProps = (state: any) => {
	const { loginError } = state.Login;
	return { loginError };
};

export default withRouter(
	connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
