import React, { Component, useEffect, useState } from 'react';

import ReactApexChart from 'react-apexcharts';

const DashedLine = (props: any) => {
    const [series, setSeries] = useState([

        { name: "Session Duration", data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10] },

        { name: "Page Views", data: [36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35] },

        { name: "Total Visits", data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49] }]);

    const [options, setOptions] = useState({
        chart: {
            zoom: { enabled: !1 }, toolbar: { show: !1 }
        },
        colors: ["#556ee6", "#f46a6a", "#34c38f"],
        dataLabels: { enabled: !1 },
        stroke: {
            width: [3, 4, 3],
            curve: "straight",
            dashArray: [8, 0, 5]
        },
        markers: { size: 0, hover: { sizeOffset: 6 } },
        xaxis: { categories: ["01 Jan", "02 Jan", "03 Jan", "04 Jan", "05 Jan", "06 Jan", "07 Jan", "08 Jan", "09 Jan", "10 Jan", "11 Jan", "12 Jan"] },
        tooltip: {
            y: [
                { title: { formatter: function (e: any) { return e } } },
                { title: { formatter: function (e: any) { return e + " per session" } } },
                { title: { formatter: function (e: any) { return e } } }]
        }, grid: { borderColor: "#f1f1f1" }
    });

    useEffect(() => {
        setSeries([{
            name: "Quantidade de atendimentos",
            data: props?.values?.map((a: any) => a.value)
        }]);

        setOptions({
            ...options, xaxis: {
                categories: props?.values?.map((a: any) => a.key),
            }
        });
    }, [props.values])

    return (
        <React.Fragment>
            <ReactApexChart series={series} type="line" height="380" />
        </React.Fragment>

    );
}

export default DashedLine;