//// ------------------------------------------------------
//// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
//// ------------------------------------------------------

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AbilityContext } from "../../hooks/Ability";

const PrismaMenu = (props: any) => {
    const ability = useContext(AbilityContext);

    return <>
        { ability.can('read', 'Activation') && <li>
            <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-store-2-line"></i>
                <span className="ml-1">{props.t('Activation')}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
                <li><Link to="/activation-list">{props.t('Listing')}</Link></li>
                { ability.can('create', 'Activation') && <li><Link to="/activation-details">{props.t('Add')}</Link></li> }
            </ul>
        </li> }
{ ability.can('read', 'Contact') && <li>
            <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-store-2-line"></i>
                <span className="ml-1">{props.t('Contact')}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
                <li><Link to="/contact-list">{props.t('Listing')}</Link></li>
                { ability.can('create', 'Contact') && <li><Link to="/contact-details">{props.t('Add')}</Link></li> }
            </ul>
        </li> }
{ ability.can('read', 'Agent') && <li>
            <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-store-2-line"></i>
                <span className="ml-1">{props.t('Agent')}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
                <li><Link to="/agent-list">{props.t('Listing')}</Link></li>
                { ability.can('create', 'Agent') && <li><Link to="/agent-details">{props.t('Add')}</Link></li> }
            </ul>
        </li> }

    </>
}


export default PrismaMenu;