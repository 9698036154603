import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, createHttpLink, from, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';

const getToken = () => {
    const token = localStorage.getItem('@Storage:token');
    return token;
}
//'http://localhost:8000/graphql'
const httpLink = createUploadLink({
    uri: window.location.hostname.indexOf('localhost') > -1 ? process.env.REACT_APP_LOCAL_GRAPHQL : process.env.REACT_APP_GRAPHQL,
}) as any;

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getToken();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        },
        options: {
            reconnect: true,
            connectionParams: {
                Authorization: getToken() ? `Bearer ${getToken()}` : '',
            },
        }
    }
});

const wsLink = new WebSocketLink({
    uri: (window.location.hostname.indexOf('localhost') > -1 ? process.env.REACT_APP_LOCAL_WS : process.env.REACT_APP_WS) || "",
    options: {
        reconnect: true,
        connectionParams: {
            Authorization: getToken() ? `Bearer ${getToken()}` : '',
        },
    },
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    ApolloLink.from([authLink, wsLink]),
    ApolloLink.from([authLink, httpLink]),
);

export const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
});



