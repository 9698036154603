import AuthLockScreen from "../pages/Authentication/AuthLockScreen";
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import ChartsKnob from "../pages/Charts/charts-knob";
import CommingSoon from "../pages/Utility/CommingSoon";
import Dashboard from "../pages/Dashboard/index";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Login1 from "../pages/AuthenticationInner/Login";
import Logout from "../pages/Authentication/Logout";
import Maintenance from "../pages/Utility/Maintenance";
import React from "react";
import { Redirect } from "react-router-dom";
import Register from "../pages/Authentication/Register";
import Register1 from "../pages/AuthenticationInner/Register";
import SparklineChart from "../pages/Charts/SparklineChart";
import { prismaRoutes } from "./prisma/index";

const authProtectedRoutes = [

	//Charts
	{ path: "/charts-apex", component: ChartApex },
	{ path: "/charts-chartjs", component: ChartjsChart },
	{ path: "/charts-sparkline", component: SparklineChart },
	{ path: "/charts-knob", component: ChartsKnob },
	{ path: "/dashboard", component: Dashboard },

	...prismaRoutes,

	{ path: "/database", component: ChartApex },
	{ path: "/chart-district", component: ChartApex },
	{ path: "/chart-research", component: ChartApex },
	{ path: "/chart-not-converted", component: ChartApex },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },
	{ path: "/auth-lock-screen", component: AuthLockScreen },

	// Authentication Inner
	{ path: "/auth-login", component: Login1 },
	{ path: "/auth-register", component: Register1 },
	{ path: "/auth-recoverpw", component: ForgetPwd1 },

	{ path: "/pages-maintenance", component: Maintenance },
	{ path: "/pages-comingsoon", component: CommingSoon },
	{ path: "/pages-404", component: Error404 },
	{ path: "/pages-500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
