import "antd/dist/antd.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';

import App from './App';
import { AuthProvider } from './hooks/Auth'
import { BrowserRouter } from 'react-router-dom';
import { GraphQLProvider } from './hooks/GraphQLProvider'
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import store from './store';

const app = (
    <GraphQLProvider>
        <AuthProvider>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </AuthProvider>
    </GraphQLProvider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
