import axios from 'axios';

const debug = process.env.REACT_APP_LOCAL_URL;
const remote = process.env.REACT_APP_URL;
let url = window.location.hostname.indexOf('localhost') > -1 ? debug : remote;

const api = axios.create({
  baseURL: url,
});

api.interceptors.request.use(function (config) {
  const token = localStorage?.getItem('@Storage:token');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export { api };
