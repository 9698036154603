// Import scss
import "./theme.scss";

import React, { Component, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes";

import AppRoute from "./routes/route";
import HorizontalLayout from "./components/HorizontalLayout";
import NonAuthLayout from "./components/NonAuthLayout";
// layouts
import VerticalLayout from "./components/VerticalLayout";
import { connect } from "react-redux";
//Fake backend
import fakeBackend from './helpers/AuthType/fakeBackend';
//Firebase helper
import { initFirebaseBackend } from "./helpers/firebase_helper";

import 'react-quill/dist/quill.snow.css';

import { AbilityContext } from "./hooks/Ability";
import { buildAbilityFor } from "./casl/ability";

import { useAuth } from './hooks/Auth';

// Activating fake backend
fakeBackend();

const App = (props) => {

	/**
   * Returns the layout
   */
	const getLayout = () => {
		let layoutCls = VerticalLayout;

		switch (props.layout.layoutType) {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};

	const Layout = getLayout();
	const { user } = useAuth();

	return (

		<AbilityContext.Provider value={buildAbilityFor(user)}>
			<Router>
				<Switch>
					{publicRoutes.map((route, idx) => (
						<AppRoute
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
							isAuthProtected={false}
						/>
					))}

					{authProtectedRoutes.map((route, idx) => (
						<AppRoute
							path={route.path}
							layout={Layout}
							component={route.component}
							key={idx}
							isAuthProtected={true}
						/>
					))}
				</Switch>
			</Router>
		</AbilityContext.Provider>
	);
};

const mapStateToProps = state => {
	return {
		layout: state.Layout,
	};
};


export default connect(mapStateToProps, null)(App);
