//// ------------------------------------------------------
//// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
//// ------------------------------------------------------

    import AgentDetails from "../../pages/prisma/agent/agent.details";
import AgentList from "../../pages/prisma/agent/agent.list";
import AgentRoleDetails from "../../pages/prisma/agentRole/agentRole.details";
import AgentRoleList from "../../pages/prisma/agentRole/agentRole.list";
import AgentRolePolicyMapDetails from "../../pages/prisma/agentRolePolicyMap/agentRolePolicyMap.details";
import AgentRolePolicyMapList from "../../pages/prisma/agentRolePolicyMap/agentRolePolicyMap.list";
import ActivationDetails from "../../pages/prisma/activation/activation.details";
import ActivationList from "../../pages/prisma/activation/activation.list";
import ActivationStatusDetails from "../../pages/prisma/activationStatus/activationStatus.details";
import ActivationStatusList from "../../pages/prisma/activationStatus/activationStatus.list";
import ContactDetails from "../../pages/prisma/contact/contact.details";
import ContactList from "../../pages/prisma/contact/contact.list";
import NotificationDetails from "../../pages/prisma/notification/notification.details";
import NotificationList from "../../pages/prisma/notification/notification.list";
import CountryDetails from "../../pages/prisma/country/country.details";
import CountryList from "../../pages/prisma/country/country.list";
import PolicyDetails from "../../pages/prisma/policy/policy.details";
import PolicyList from "../../pages/prisma/policy/policy.list";
import PolicyStatusDetails from "../../pages/prisma/policyStatus/policyStatus.details";
import PolicyStatusList from "../../pages/prisma/policyStatus/policyStatus.list";
import ReportDetails from "../../pages/prisma/report/report.details";
import ReportList from "../../pages/prisma/report/report.list";
import LogDetails from "../../pages/prisma/log/log.details";
import LogList from "../../pages/prisma/log/log.list";
import PublicFileDetails from "../../pages/prisma/publicFile/publicFile.details";
import PublicFileList from "../../pages/prisma/publicFile/publicFile.list";
import PrivateFileDetails from "../../pages/prisma/privateFile/privateFile.details";
import PrivateFileList from "../../pages/prisma/privateFile/privateFile.list";


const prismaRoutes = [
    { path: "/agent-list", component: AgentList },
    { path: "/agent-details/:guid?", component: AgentDetails },
{ path: "/agentRole-list", component: AgentRoleList },
    { path: "/agentRole-details/:guid?", component: AgentRoleDetails },
{ path: "/agentRolePolicyMap-list", component: AgentRolePolicyMapList },
    { path: "/agentRolePolicyMap-details/:guid?", component: AgentRolePolicyMapDetails },
{ path: "/activation-list", component: ActivationList },
    { path: "/activation-details/:guid?", component: ActivationDetails },
{ path: "/activationStatus-list", component: ActivationStatusList },
    { path: "/activationStatus-details/:guid?", component: ActivationStatusDetails },
{ path: "/contact-list", component: ContactList },
    { path: "/contact-details/:guid?", component: ContactDetails },
{ path: "/notification-list", component: NotificationList },
    { path: "/notification-details/:guid?", component: NotificationDetails },
{ path: "/country-list", component: CountryList },
    { path: "/country-details/:guid?", component: CountryDetails },
{ path: "/policy-list", component: PolicyList },
    { path: "/policy-details/:guid?", component: PolicyDetails },
{ path: "/policyStatus-list", component: PolicyStatusList },
    { path: "/policyStatus-details/:guid?", component: PolicyStatusDetails },
{ path: "/report-list", component: ReportList },
    { path: "/report-details/:guid?", component: ReportDetails },
{ path: "/log-list", component: LogList },
    { path: "/log-details/:guid?", component: LogDetails },
{ path: "/publicFile-list", component: PublicFileList },
    { path: "/publicFile-details/:guid?", component: PublicFileDetails },
{ path: "/privateFile-list", component: PrivateFileList },
    { path: "/privateFile-details/:guid?", component: PrivateFileDetails },

];

export { prismaRoutes };
