//// ------------------------------------------------------
        //// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
        //// ------------------------------------------------------

        import {
            AutoComplete,
            Button,
            Cascader,
            DatePicker,
            Form,
            Input,
            InputNumber,
            Radio,
            Select,
            Switch,
            TreeSelect,
            Tooltip,
            Tabs
        } from 'antd';
        import Autocomplete from '@material-ui/lab/Autocomplete';
        import { Card, Col, Container, Row, Modal } from 'react-bootstrap';
        import React, { useEffect, useState } from 'react';
        import { useMutation, useQuery } from '@apollo/client';
        import { client } from '../../../hooks/GraphQLProvider';
        import Breadcrumbs from '../../../components/Common/Breadcrumb';
        import { CardBody } from 'reactstrap';
        import gql from 'graphql-tag-ts';
        import toastr from 'toastr';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TextField from '@material-ui/core/TextField';
        import moment from 'moment';
        import { BiMessageEdit, RiChatNewLine, FiDelete, TiDelete } from 'react-icons/all';
        import { Report } from '../../../models/prisma/report.model';
        import { GET_LIST, startedFilter } from './report.list';
        import { withNamespaces } from "react-i18next";
        import { StorageService } from '../../../services/storage.service';
        import { Config } from '../../../config';
        import { BasePage } from '../../base.page';
        import ReactQuill from 'react-quill';

        
        const { Option } = AutoComplete;
        const { TabPane } = Tabs;

        const QUERY = gql<{
            report: Report
        }>`
        query Report($id: String)
        {
            report(id: $id)
          {
            id
                    description
                    deleted
                    createdAt
                    updatedAt
                    start
                    end
                    periodo
                    name
                    tag1
                    tag2
                    tag3
                    tag4
                    key
                    value
                    
          }
        }
        `;

        const CREATE_MUTATION = gql<Report>`
        mutation ReportSave(
            $id: String,
                        $description: String,
                        $start: String!,
                        $end: String!,
                        $periodo: String,
                        $name: String,
                        $tag1: String,
                        $tag2: String,
                        $tag3: String,
                        $tag4: String,
                        $key: String,
                        $value: Int,
                        
        )
        {
            reportCreate(data: {
                id: $id,
                    description: $description,
                    start: $start,
                    end: $end,
                    periodo: $periodo,
                    name: $name,
                    tag1: $tag1,
                    tag2: $tag2,
                    tag3: $tag3,
                    tag4: $tag4,
                    key: $key,
                    value: $value,
                    
            }) {
                id
                    description
                    deleted
                    createdAt
                    updatedAt
                    start
                    end
                    periodo
                    name
                    tag1
                    tag2
                    tag3
                    tag4
                    key
                    value
                    
            }
        }
        `;

        const UPDATE_MUTATION = gql<Report>`
        mutation ReportSave(
            $id: String,
                        $description: String,
                        $start: String!,
                        $end: String!,
                        $periodo: String,
                        $name: String,
                        $tag1: String,
                        $tag2: String,
                        $tag3: String,
                        $tag4: String,
                        $key: String,
                        $value: Int,
                        
        )
        {
            reportUpdate(data: {
                id: $id,
                    description: $description,
                    start: $start,
                    end: $end,
                    periodo: $periodo,
                    name: $name,
                    tag1: $tag1,
                    tag2: $tag2,
                    tag3: $tag3,
                    tag4: $tag4,
                    key: $key,
                    value: $value,
                    
            }) {
                id
                    description
                    deleted
                    createdAt
                    updatedAt
                    start
                    end
                    periodo
                    name
                    tag1
                    tag2
                    tag3
                    tag4
                    key
                    value
                    
            }
        }
        `;

        

        const ReportDetails = (props: any) => {
            const [form] = Form.useForm();
            const [dataSources, setDataSources] = useState<any>({});
            const [modals, setModals] = useState<any>({});
            const [download, setDownload] = useState<any>({} as any);
            const [files, setFiles] = useState<any>({} as any);
            const [selects, setSelects] = useState<any>({} as any);
            const [uncheck, setUncheck] = useState<any>({} as any);
            const [dropUpload, setDropUpload] = useState<any>({} as any);
            const [descriptions, setDescriptions] = useState<any>({} as any);


            const handleSearch = async ({ search, query, model, idField, textField }: { search: string, query: string, model: string, idField: string, textField: string }) => {
                try {
                    const { data } = await client.query({
                        query: gql(`${query}`),
                        variables: { search: { search, type: 'contains', field: textField } }
                    });
                    if (data) {

                        for (const index in data) {
                            const obj: any = {};
                            const edges = data[index].edges;

                            for (let item of edges) {
                                obj[item.node[idField]] = item.node;
                            }

                            setDataSources({
                                ...dataSources,
                                [model]: [...edges.map((e: any) => e.node)],
                                [`${model}Obj`]: obj
                            });
                        }


                    }
                } catch (error) {
                    toastr.error("Sorry, load data failed =(");
                }
            };

            const { data, loading } = useQuery<{ report: Report }>(QUERY, {
                variables: { id: props.match?.params?.guid },
                skip: !props.match?.params?.guid
            });

            const databaseEntity = data?.report || {} as any;

            const reset = () => {
                setUncheck({});
                setDropUpload({});
                setFiles({});
                setSelects({});
            }

            function downloadFile(url: string) {
                if (download[url] || download[url] == false) {
                    return download[url];
                }

                setDownload({ ...download, [url]: false });

                StorageService.download(url).then((result) => {
                    if (!result.error) {
                        setDownload({ ...download, [url]: result.data });
                    }
                });

                return undefined;
            }

            const onSubmit = async (entity: any) => {

                for (const key in entity) {
                    if (entity[key] === undefined || entity[key] === null) {
                        delete entity[key];
                    }

                    // remove object type
                    if (entity[key] && typeof entity[key] === 'object') {
                        delete entity[key].__typename;
                    }

                    // format date
                    if (entity[key] && entity[key]._isAMomentObject) {
                        entity[key] = entity[key].format();
                    }
                }

                // do upload
                for (const fkey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, fkey)) {
                        const file = files[fkey];
                        if (fkey.toLowerCase().indexOf('pub') > -1) {
                            const result = await StorageService.publicUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }
                        else {
                            const result = await StorageService.privateUpload(file);
                            entity[fkey] = result.data!.id;
                            entity[fkey.replaceAll('Id', 'Url')] = result.data!.url;
                        }

                    }
                }

                // select from dropdown
                for (const skey in selects) {
                    if (Object.prototype.hasOwnProperty.call(selects, skey)) {
                        if (selects[skey]) {
                            entity[skey] = selects[skey];
                        }
                    }
                }

                // add from descriptions
                for (const skey in descriptions) {
                    if (Object.prototype.hasOwnProperty.call(descriptions, skey)) {
                        if (descriptions[skey]) {
                            entity[skey] = descriptions[skey];
                        }
                    }
                }

                if (dataSources.dependencies) {
                    for(const key in dataSources.dependencies) {
                        if(typeof dataSources.dependencies[key] === 'string') {
                            entity[key] = dataSources.dependencies[key];
                            //delete entity[key].__typename;
                        }
                        else {
                            delete entity[key];
                        }
                    }
                }

                // uncheck dropdown
                for (const skey in uncheck) {
                    if (Object.prototype.hasOwnProperty.call(uncheck, skey) && uncheck[skey]) {
                        if (uncheck[skey] && databaseEntity && databaseEntity[skey]) {
                            entity[skey] = "";
                        }
                        else {
                            delete entity[skey];
                        }
                    }
                }

                // delete uploaded files
                for (const ukey in dropUpload) {
                    if (Object.prototype.hasOwnProperty.call(dropUpload, ukey)) {
                        if (dropUpload[ukey]) {
                            entity[ukey] = "";
                        }
                    }
                }

                // case has a relation
                if(props.relation) {
                    entity[props.relation.field] = props.relation.id
                }

                const success = (entity: any) => {
                    for (const key in data) {
                        if (dataSources.hasOwnProperty(key)) {
                            delete dataSources[key];
                        }
                    }

                    reset();

                    toastr.success("Report has been saved");
                    const model = entity?.data?.reportUpdate || entity?.data?.reportCreate;
                    if(!props.match?.params?.guid && !props.isModal){
                        props.history.push('/report-details/' + model.id);
                    }
                    else if(!!props.isModal && !!props.modalOnClose) {
                        props.modalOnClose(model);
                    }
                }

                if (!props.match?.params?.guid) {

                    create({
                        variables: entity
                    })
                        .then(success)
                        .catch((error) => {
                            toastr.error("Sorry, your data submission failed =(");
                        });
                }
                else {

                    update({
                        variables: { id: props.match?.params?.guid, ...entity }
                    })
                        .then(success)
                        .catch((error) => {
                            toastr.error("Sorry, your data submission failed =(");
                        });
                }
            };

            useEffect(() => {
                if (databaseEntity) {
                    form.setFieldsValue(databaseEntity);
                }
                else if(props.relation) {
                    dataSources.dependencies = dataSources.dependencies || {};
                    dataSources.dependencies[props.relation.field] = props.relation.id;
                    dataSources.dependencies[props.relation.model] = {
                        [props.relation.idField]: props.relation.id,
                        [props.relation.textField]: props.relation.text
                    }
                    setDataSources({...dataSources});
                }

                return () => {
                }
            }, [setDataSources, data]);

            const [create] = useMutation(CREATE_MUTATION, {
                refetchQueries: [{
                    query: GET_LIST,
                    variables: props.relation ? {...startedFilter, [props.relation.field]: props.relation.id } : startedFilter
                }]
            });
            const [update] = useMutation(UPDATE_MUTATION);

            BasePage.prepare(form.setFieldsValue);

            return (
                <React.Fragment>
                    <div className="page-content" style={props.isModal ? {paddingTop: 0, paddingBottom: 0} : {}}>
                        <Container fluid>
                            {!props.isModal && <Breadcrumbs title={props.t('Report')} breadcrumbItems={[
                                { title: props.t("Report"), link: "#" },
                                { title: props.t("Details"), link: "#" },
                            ]} /> }

                            <Row>
                                <Col xs={12}>
                                    <Tabs defaultActiveKey="0" tabPosition={"top"}>
                                        <TabPane key={0} tab={props.t("Details")}>
                                            <Card>
                                                <CardBody>
                                                    <h4 className="card-title">{props.t('Report')} {props.t('Details')}</h4>
                                                    <p className="card-title-desc">{props.t('Create/Edit')} {props.t('Report')} {props.t('inputs and depencencies')}</p>
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 4 }}
                                                        wrapperCol={{ span: 14 }}
                                                        layout="horizontal"
                                                        onFinish={onSubmit}
                                                    >
                                                        <Form.Item label={props.t("Description")} name="description"
                            rules={[{ required: true, message: 'Please input the ' + props.t("Description") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={props.t("Start")} name="start"
                            rules={[{ required: true, message: 'Please input the ' + props.t("Start") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.start ? moment(databaseEntity?.start, 'YYYY-MM-DD') : undefined} />
                            }
                        </Form.Item>
                            <Form.Item label={props.t("End")} name="end"
                            rules={[{ required: true, message: 'Please input the ' + props.t("End") + '!' }]} valuePropName="aria-label">
                            {(!props.match?.params?.guid || (props.match?.params?.guid && data)) &&
                                <DatePicker showTime defaultValue={databaseEntity?.end ? moment(databaseEntity?.end, 'YYYY-MM-DD') : undefined} />
                            }
                        </Form.Item>
                            <Form.Item label={props.t("Periodo")} name="periodo"
                            rules={[{ required: true, message: 'Please input the ' + props.t("Periodo") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={props.t("Name")} name="name"
                            rules={[{ required: true, message: 'Please input the ' + props.t("Name") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={props.t("Tag1")} name="tag1"
                            rules={[{ required: false, message: 'Please input the ' + props.t("Tag1") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={props.t("Tag2")} name="tag2"
                            rules={[{ required: false, message: 'Please input the ' + props.t("Tag2") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={props.t("Tag3")} name="tag3"
                            rules={[{ required: false, message: 'Please input the ' + props.t("Tag3") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={props.t("Tag4")} name="tag4"
                            rules={[{ required: false, message: 'Please input the ' + props.t("Tag4") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={props.t("Key")} name="key"
                            rules={[{ required: true, message: 'Please input the ' + props.t("Key") + '!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={props.t("Value")} name="value"
                            rules={[{ required: true, message: 'Please input the ' + props.t("Value") + ')!' }]}>
                            <InputNumber />
                        </Form.Item>
                            
                                                        <Form.Item wrapperCol={{ offset: 4 }}>
                                                            <Button type="primary" htmlType="submit">
                                                                {props.t('Submit')}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        
                                    </Tabs>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }

        export default withNamespaces()(ReportDetails);
  